<template>
  <div eagle-form-item="list-component">
    <component
      v-if="customListComponent"
      :listInForm="true"
      :listInFormIndexParams="indexParams"
      :containerFormKey="formKey"
      :is="customListComponent"
    ></component>
  </div>
</template>

<script lang="babel" type="text/babel">
import formItemMixin from '@/components/form/mixins/formItemMixin'
export default {
  mixins: [formItemMixin],
  computed: {
    customListComponent() {
      return this.formDataItem.listComponent
    },
    indexParams() {
      if(typeof this.formDataItem.indexParams != 'function') return null
      return this.formDataItem.indexParams
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
